import React from 'react';
import linkedIn from '../../assets/images/linkedin.png';
import './CoreManagementTeam.css';
import { useInView } from 'react-intersection-observer';

const CoreManagementTeam = () => {
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const core = [
    // {
    //   img: 'Pushkar_Salunkhe.png',
    //   name: 'Pushkar Salunkhe',
    //   post1: 'CTO ',
    //   post2: '',
    //   linkedin: 'https://www.linkedin.com/in/pushkar-salunkhe-9a1b1a7/',
    // },
    // {
    //   img: 'Mukesh_Ranjan.png',
    //   name: 'Mukesh Ranjan',
    //   post1: 'CBO-SaaS',
    //   post2: '',
    //   linkedin: 'https://www.linkedin.com/in/mukesh-ranjan-77904825/ ',
    // },
    {
      img: 'Nishchint_Paliwal.png',
      name: 'Nischint Paliwal',
      post1: 'CCO',
      post2: '',
      linkedin: 'https://www.linkedin.com/in/nishchint-paliwal-318161b6/ ',
    },
    {
      img: 'Romil_Gupta.png',
      name: 'Romil Gupta',
      post1: 'Business Head',
      post2: 'Distribution & Risk',
      linkedin:
        'https://www.linkedin.com/in/romil-gupta-4089aa21?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ',
    },

    {
      img: 'vishal_puri.png',
      name: 'Vishal Puri',
      post1: 'Business Head',
      post2: 'DGV Connect & Marketing',
      linkedin: 'https://www.linkedin.com/in/vishal-puri-67706997/',
    },
    {
      img: 'Abhishek_Lewis.png',
      name: 'Abhishek Lewis',
      post1: 'Head',
      post2: 'Micro Dairy Enterprise & Insurance',
      linkedin: 'https://www.linkedin.com/in/vishal-puri-67706997/',
    },
    {
      img: 'Hardeep_Singh.png',
      name: 'Hardeep Kumar Singh',
      post1: 'Head',
      post2: 'Technology',
      linkedin: 'https://www.linkedin.com/in/vishal-puri-67706997/',
    },
    {
      img: 'Surya_Reddy.png',
      name: 'P Surya Naryana Reddy',
      post1: 'Head',
      post2: 'Operations',
      linkedin:
        'https://www.linkedin.com/in/suryanarayana-reddy-p-60b5a4103?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
      img: 'Sangeetha_Lewis.png',
      name: 'Sangeetha Lewis',
      post1: 'DVP',
      post2: 'Banking & Dairy',
      linkedin: 'https://www.linkedin.com/in/sangeeta-lewis/',
    },
    {
      img: 'Pratik_Poojari.png',
      name: 'Pratik poojari',
      post1: 'Lead',
      post2: 'Products',
      linkedin: 'https://www.linkedin.com/in/sangeeta-lewis/',
    },
    {
      img: 'saketh_vemu.png',
      name: 'Saketh Vemu',
      post1: 'Lead',
      post2: 'DGV Digital Insurance',
      linkedin: 'https://www.linkedin.com/in/vishal-puri-67706997/',
    },
  ];

  return (
    <section ref={ref} className="core-management-wrapper">
      <div className="title">Core Management Team</div>
      <div className="core_team_main">
        {core.map(({ linkedin, post1, post2, name, img }, i) => {
          return (
            <div
              className={
                inView
                  ? `team_member_plot animate__animated animate__bounceIn animate__delay-${i}s`
                  : 'team_member_plot'
              }
              key={i}
            >
              <div className={`team_member `} key={i}>
                <img src={require(`../../assets/images/${img}`)} alt="core_team_member" />
                {/* <a className="linkedIn_img" href={linkedin} target="_blank" rel="noreferrer">
                  <img src={linkedIn} alt="linkedIn_icon" />
                </a> */}
              </div>
              <div>
                <div className="member_name">{name}</div>
                <div className="designation">
                  {post1} <br />
                  {post2}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CoreManagementTeam;
