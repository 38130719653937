import './Disclaimer.css';
import disclaimer from '../../assets/images/disclaimer.png';
const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <p className="disclaimer-head">*Disclaimer:</p>
      <div className="disclaimer-image">
        <img src={disclaimer} alt="disclaimer_img"></img>
      </div>
    </div>
  );
};

export default Disclaimer;
