import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './BoardOfDirector.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import BOD_image_1 from '../../assets/images/Who we are images/director-image-1.png';
import BOD_logo_1 from '../../assets/images/Who we are images/director-logo-1.png';
import BOD_image_2 from '../../assets/images/Who we are images/BOD_image_2.png';
import BOD_logo_2 from '../../assets/images/Who we are images/BOD_logo_2.png';
import BOD_image_3 from '../../assets/images/Who we are images/BOD_image_3.png';
import BOD_logo_3 from '../../assets/images/Who we are images/BOD_logo_3.png';
import BOD_image_4 from '../../assets/images/Who we are images/BOD_image_4.png';
import BOD_logo_4 from '../../assets/images/Who we are images/BOD_logo_4.png';
import BOD_image_5 from '../../assets/images/Who we are images/BOD_image_5.png';
import BOD_logo_5 from '../../assets/images/Who we are images/BOD_logo_5.png';
import BOD_image_6 from '../../assets/images/Who we are images/BOD_image_6.png';
import BOD_logo_6 from '../../assets/images/Who we are images/BOD_logo_6.png';
import BOD_logo_7 from '../../assets/images/Who we are images/BOD_logo_7.png';
import BOD_image_7 from '../../assets/images/Who we are images/BOD_image_7.png';

export default function BoardOfDirector() {
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });

  // const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width:1024px)').matches);
  // useEffect(() => {
  //   window.matchMedia('(min-width: 1024px)').addEventListener('change', (e) => setIsDesktop(e.matches));
  // }, []);

  // const { ref, inView } = useInView({});
  // const direcotrs = [
  //   {
  //     id: 1,
  //     img: 'aruna.png',
  //     name: 'Aruna Sundararajan, IAS',
  //     info: [
  //       {
  //         id: 1,
  //         data: '1982 batch IAS Kerala Cadre.',
  //       },
  //       {
  //         id: 2,
  //         data: 'Ex Secretary Telecom, MEITY & Steel, Govt. of India.',
  //       },

  //       {
  //         id: 3,
  //         data: 'Spearheaded Digital India at MEITY, Digital Payments, and BHIM Payments.',
  //       },

  //       {
  //         id: 4,
  //         data: 'Recipient of Top Professional Women Achievers by Forbes Business Magazine in 2012.',
  //       },

  //       {
  //         id: 5,
  //         data: 'Established the National Cyber Control Centre and National Digital Communications Policy 2018',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  //   {
  //     id: 2,
  //     img: 'subramanian.png',
  //     name: 'MV Subramanian',
  //     info: [
  //       {
  //         id: 1,
  //         data: 'Career banker for over 35 yrs.',
  //       },
  //       {
  //         id: 2,
  //         data: 'Worked in two Nationalized banks, Standard Chartered Bank & Axis Bank.',
  //       },

  //       {
  //         id: 3,
  //         data: 'Retired as President, Rural and Inclusive Banking at Axis Bank in 2014.',
  //       },

  //       {
  //         id: 4,
  //         data: 'Headed Risk, Business Banking at Axis Bank.',
  //       },

  //       {
  //         id: 5,
  //         data: 'Independent Director on the Board of GlobalSpace Technologies Ltd. and Viridian Capital & Finance Pvt. Ltd.',
  //       },

  //       {
  //         id: 6,
  //         data: 'Board of Trustees of ToyBank',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  //   // {
  //   //   id: 3,
  //   //   img: 'sabitha.png',
  //   //   name: 'Sabitha Venkatesan',
  //   //   info: [
  //   //     {
  //   //       id: 1,
  //   //       data: 'Founder Director, DigiVriddhi (DGV).',
  //   //     },
  //   //     {
  //   //       id: 2,
  //   //       data: 'Worked in International Co-operative Alliance (ICA) for 18 years, spearheading their digitization efforts in the Co-operative Sector and managing their library Worked in NCUI as Chief Librarian in New Delhi.',
  //   //     },
  //   //   ],
  //   //   delay: '0s',
  //   // },
  //   {
  //     id: 3,
  //     img: 'ragavan.png',
  //     name: 'Ragavan Venkatesan',
  //     info: [
  //       {
  //         id: 1,
  //         data: 'Founder MD & CEO of DigiVriddhi (DGV). ',
  //       },
  //       {
  //         id: 2,
  //         data: 'Founding Team Member of NPCI.',
  //       },

  //       {
  //         id: 3,
  //         data: 'Founding Team Member of IDFC Bank.',
  //       },
  //       {
  //         id: 4,
  //         data: 'Built Aadhaar Based Payments – AEPS & APBS and BHIM Aadhaar Pay.',
  //       },
  //       {
  //         id: 5,
  //         data: 'Worked closely with UIDAI, NITI Aayog, DFS (MoF) and MEITY.',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  //   {
  //     id: 4,
  //     img: 'sowmiya.png',
  //     name: 'Sowmiya Venkatesan',
  //     info: [
  //       {
  //         id: 1,
  //         data: 'Top 6 MasterChef Singapore',
  //       },
  //       {
  //         id: 2,
  //         data: 'She is the Founder of Kechil Kitchen, Singapore,- A Digital Food Enterprise Curating Menu for Diners & Viewers to offer a Culinary Adventure',
  //       },
  //       {
  //         id: 3,
  //         data: 'Consultant to Food Companies in Singapore defining Culinary Uses & Developing Recipies.',
  //       },
  //       {
  //         id: 4,
  //         data: 'An MBA in Marketing worked with IBM, Frost & Sullivan, Hexaware prior to her Entrepreneurship Stint.',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  //   {
  //     id: 5,
  //     img: 'treasa.png',
  //     name: 'Treasa Mathew',
  //     info: [
  //       {
  //         id: 1,
  //         data: 'She is a Nominee director @ DGV and represents Omidyar Network India.',
  //       },
  //       {
  //         id: 2,
  //         data: 'She is Director, Investments at Omidyar Network India',
  //       },

  //       {
  //         id: 3,
  //         data: 'Chartered Accountant with an MBA from the Indian School of Business, Hyderabad.',
  //       },
  //       {
  //         id: 4,
  //         data: 'Fourteen years of experience in the Indian venture capital and private equity industry.',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  //   {
  //     id: 6,
  //     img: 'reihem.png',
  //     name: 'Reihem Roy',
  //     info: [
  //       {
  //         id: 1,
  //         data: 'He is a Nominee director @ DGV and represents Omnivore.',
  //       },
  //       {
  //         id: 2,
  //         data: 'He is Partner at Omnivore.',
  //       },

  //       {
  //         id: 3,
  //         data: 'Reihem began his career as an Analyst at the United Nations International Fund for Agricultural Development (IFAD), working on agribusiness investments in West and Central Africa.',
  //       },
  //       {
  //         id: 4,
  //         data: 'Earned a BA in Economics from the University of Madras and an MSc in Environmental Economics from the University of East Anglia.',
  //       },
  //     ],
  //     delay: '0s',
  //   },
  // ];
  // const eventSettings = {
  //   dots: false,
  //   infinite: true,
  //   arrows: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   variableWidth: true,
  //   adaptiveHeight: true,
  //   autoplaySpeed: 2000,
  //   pauseOnHover: true,
  //   cssEase: 'linear',
  //   // initialSlide: 'slide',

  //   responsive: [
  //     {
  //       breakpoint: 580,
  //       settings: {
  //         adaptiveHeight: true,
  //         variableWidth: true,
  //         slidesToShow: 1.5,
  //       },
  //     },
  //   ],
  // };
  return (
    // <div className="_board_of_director">
    //   <div className="section_title">Board of Directors</div>
    //   <div ref={ref} className="director_block_wrapper">
    //     {isDesktop ? (
    //       <div className="director_block_slider">
    //         <Slider {...eventSettings}>
    //           {direcotrs.map((dir) => (
    //             <div
    //               className={
    //                 inView
    //                   ? `director_block animate__animated animate__fadeInUp_1 animate__delay-${dir.delay}`
    //                   : 'director_block'
    //               }
    //               key={dir.id}
    //             >
    //               <div className="director-img">
    //                 <img src={require(`../../assets/images/${dir.img}`)} alt="Director" />
    //                 <div className="director-info">
    //                   <ul className="director-info-ul">
    //                     {dir.info.map((infoList) => (
    //                       <li className="director-info-list" key={infoList.id}>
    //                         {infoList.data}
    //                       </li>
    //                     ))}
    //                   </ul>
    //                 </div>
    //               </div>
    //               <div className="director-name">{dir.name}</div>
    //             </div>
    //           ))}
    //         </Slider>
    //       </div>
    //     ) : (
    //       direcotrs.map((dir) => (
    //         <div
    //           className={
    //             inView
    //               ? `director_block animate__animated animate__fadeInUp_1 animate__delay-${dir.delay}`
    //               : 'director_block'
    //           }
    //           key={dir.id}
    //         >
    //           <div className="director-img">
    //             <img src={require(`../../assets/images/${dir.img}`)} alt="Director" />
    //           </div>
    //           <div className="director-name">{dir.name}</div>
    //           <div className="director-info">
    //             <ul className="director-info-ul">
    //               {dir.info.map((infoList) => (
    //                 <li className="director-info-list" key={infoList.id}>
    //                   {infoList.data}
    //                 </li>
    //               ))}
    //             </ul>
    //           </div>
    //         </div>
    //       ))
    //     )}
    //   </div>
    // </div>

    <div className="director-main-container" ref={ref}>
      <div className="director-text-section">Board of Directors</div>
      <div className="director-info-section">
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-0s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_1} alt={'BOD_1'} />
          </div>
          <div className="director-nane">Aruna Sundararajan</div>
          <div className="director-company-logo">
            <img src={BOD_logo_1} alt="BOD_logo_1" />
          </div>
        </div>
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-1s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_2} alt={'BOD_2'} />
          </div>
          <div className="director-nane">M V Subramanian</div>
          <div className="director-company-logo">
            <img src={BOD_logo_2} alt="BOD_logo_2" />
          </div>
        </div>
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-2s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_3} alt={'BOD_3'} />
          </div>
          <div className="director-nane">Ragavan Venkatesan</div>
          <div className="director-company-logo">
            <img src={BOD_logo_3} alt="BOD_logo_3" />
          </div>
        </div>
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-3s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_4} alt={'BOD_4'} />
          </div>
          <div className="director-nane">Sowmiya Venkatesan</div>
          <div className="director-company-logo">
            <img src={BOD_logo_4} alt="BOD_logo_4" />
          </div>
        </div>
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-3s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_7} alt={'BOD_4'} />
          </div>
          <div className="director-nane">Amol B Warange</div>
          <div className="director-company-logo">
            <img src={BOD_logo_7} alt="BOD_logo_7" />
          </div>
        </div>
        {/* <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-4s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_5} alt={'BOD_5'} />
          </div>
          <div className="director-nane">Treasa Mathew</div>
          <div className="director-company-logo">
            <img src={BOD_logo_5} alt="BOD_logo_5" />
          </div>
        </div> */}
        <div
          className={
            inView ? 'director-wrapper animate__animated animate__flipInY animate__delay-5s' : 'director-wrapper'
          }
        >
          <div className="director-image">
            <img src={BOD_image_6} alt={'BOD_5'} />
          </div>
          <div className="director-nane">Reihem Roy</div>
          <div className="director-company-logo">
            <img src={BOD_logo_6} alt="BOD_logo_5" />
          </div>
        </div>
      </div>
    </div>
  );
}
