import React from 'react';
import { PRODUCT_INSUARANCE_INFO, INSURANCE_SLIDER_IMAGES } from '../../../utils/constants';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import './DGVDigitalInsurance.css';
import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
import insuranceImage from '../../../assets/images/insurance-image.png';
import insuranceBenefitDesk_1 from '../../../assets/images/insurance-benefit-desk-1.png';
import insuranceBenefitDesk_2 from '../../../assets/images/insurance-benefit-desk-2.png';
import insuranceBenefitDesk_3 from '../../../assets/images/insurance-benefit-desk-3.png';
import insuranceBenefitDesk_4 from '../../../assets/images/insurance-benefit-desk-4.png';
import insuranceBenefitDesk_5 from '../../../assets/images/insurance-benefit-desk-5.png';
import insuranceBenefitDesk_6 from '../../../assets/images/insurance-benefit-desk-6.png';
import { useInView } from 'react-intersection-observer';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';

const DGVDigitalInsurance = () => {
  const { ref, inView: logoView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const [ref2, inView] = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  return (
    <div className="main-div desktop-view">
      <Header />
      <BreadCrumb />

      <div className="page-container page-content">
        <ProductInfoPlot info={PRODUCT_INSUARANCE_INFO} images={INSURANCE_SLIDER_IMAGES} />
      </div>
      {/* <div className="insurance-image-container">
        <img src={insuranceImage} alt="insurance-img" />
      </div> */}
      <div
        ref={ref}
        className={
          logoView ? `insurance-image-container animate__animated animate__flipInY ` : 'insurance-image-container'
        }
      >
        <img src={insuranceImage} alt="DGV Pay Graphic" />
      </div>

      {/* <BenefitsPay Benefits={BenefitsPayData} /> */}
      <div ref={ref2} className="insurance-benefit page-container">
        <div className="insurance-benefits-title">Benefits</div>
        <div className="insurance-Product-benefits">
          <div className="benefits-box">
            <div
              // className="benefites-img"
              className={inView ? `benefites-img animate__animated animate__bounceIn` : 'benefites-img'}
            >
              <img src={insuranceBenefitDesk_1} alt="" />
            </div>
          </div>
          <div className="benefits-box">
            <div
              className={
                inView ? `benefites-img animate__animated animate__bounceIn animate__delay-1s` : 'benefites-img'
              }
            >
              <img src={insuranceBenefitDesk_2} alt="" />
            </div>
          </div>
          <div className="benefits-box">
            <div
              className={
                inView ? `benefites-img animate__animated animate__bounceIn animate__delay-2s` : 'benefites-img'
              }
            >
              <img src={insuranceBenefitDesk_3} alt="" />
            </div>
          </div>
          <div className="benefits-box">
            <div
              className={
                inView ? `benefites-img animate__animated animate__bounceIn animate__delay-3s` : 'benefites-img'
              }
            >
              <img src={insuranceBenefitDesk_4} alt="" />
            </div>
          </div>
          <div className="benefits-box">
            <div
              className={
                inView ? `benefites-img animate__animated animate__bounceIn animate__delay-4s` : 'benefites-img'
              }
            >
              <img src={insuranceBenefitDesk_5} alt="" />
            </div>
          </div>
          <div className="benefits-box">
            <div
              className={
                inView ? `benefites-img animate__animated animate__bounceIn animate__delay-5s` : 'benefites-img'
              }
            >
              <img src={insuranceBenefitDesk_6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <ForMoreDetails mailId={'digitalinsurance@dgv.in'} />
      <Disclaimer />
      <Footer />
    </div>
  );
};

export default DGVDigitalInsurance;
