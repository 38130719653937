import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { geoCentroid } from 'd3-geo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { geoData } from './mapData';
import './state-map.css';

const stateColorArray = [
  { stateName: 'Bihar', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Gujarat', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Jharkhand', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Karnataka', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Madhya Pradesh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Maharashtra', active: '#08ac5d', Q3: '#094E8F', Q4: '#094E8F' },
  { stateName: 'Rajasthan', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Tamil Nadu', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Telangana', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Uttar Pradesh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Uttarakhand', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Andhra Pradesh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Andaman and Nicobar Islands', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Arunachal Pradesh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Assam', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Chandigarh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Chhattisgarh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Dadra and Nagar Haveli', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Daman and Diu', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Goa', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Haryana', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Himachal Pradesh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Kerala', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Lakshadweep', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Manipur', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Meghalaya', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Mizoram', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Nagaland', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Delhi', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Puducherry', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Punjab', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F', FY: '#F178E9' },
  { stateName: 'Sikkim', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Tripura', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'West Bengal', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Odisha', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Jammu and Kashmir', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
  { stateName: 'Ladakh', active: '#08ac5d', Q3: '#681C4F', Q4: '#094E8F' },
];
const stateDetailsArray = [
  { Maharashtra: { shortName: 'MH', xCoordinate: -7, yCoordinate: 5 } },
  { Gujarat: { shortName: 'GJ', xCoordinate: -7, yCoordinate: 5 } },
  { Bihar: { shortName: 'BR', xCoordinate: -7, yCoordinate: 5 } },
  { Jharkhand: { shortName: 'JH', xCoordinate: -7, yCoordinate: 5 } },
  { Karnataka: { shortName: 'KA', xCoordinate: -7, yCoordinate: 5 } },
  { 'Madhya Pradesh': { shortName: 'MP', xCoordinate: -7, yCoordinate: 5 } },
  { Rajasthan: { shortName: 'RJ', xCoordinate: -7, yCoordinate: 5 } },
  { 'Tamil Nadu': { shortName: 'TN', xCoordinate: -7, yCoordinate: 5 } },
  { Telangana: { shortName: 'TS', xCoordinate: -7, yCoordinate: 5 } },
  { 'Uttar Pradesh': { shortName: 'UP', xCoordinate: -7, yCoordinate: 5 } },
  { Uttarakhand: { shortName: 'UK', xCoordinate: -7, yCoordinate: 5 } },
  { 'Andhra Pradesh': { shortName: 'AP', xCoordinate: 10, yCoordinate: 20 } },
  { 'Andaman And Nicobar Islands': { shortName: 'AN', xCoordinate: -7, yCoordinate: 5 } },
  { 'Arunachal Pradesh': { shortName: 'AR', xCoordinate: -7, yCoordinate: 5 } },
  { Assam: { shortName: 'AS', xCoordinate: -7, yCoordinate: 5 } },
  { Chandigarh: { shortName: 'CH', xCoordinate: -7, yCoordinate: 5 } },
  { Chhattisgarh: { shortName: 'CT', xCoordinate: -7, yCoordinate: 5 } },
  { 'Dadra And Nagar Haveli': { shortName: 'DN', xCoordinate: -7, yCoordinate: 5 } },
  { 'Daman And Diu': { shortName: 'DD', xCoordinate: -7, yCoordinate: 5 } },
  { Goa: { shortName: 'GA', xCoordinate: -7, yCoordinate: 5 } },
  { Haryana: { shortName: 'HR', xCoordinate: -7, yCoordinate: 5 } },
  { 'Himachal Pradesh': { shortName: 'HP', xCoordinate: -7, yCoordinate: 5 } },
  { Kerala: { shortName: 'KL', xCoordinate: -7, yCoordinate: 5 } },
  { Lakshadweep: { shortName: 'LD', xCoordinate: -7, yCoordinate: 5 } },
  { Manipur: { shortName: 'MN', xCoordinate: -7, yCoordinate: 5 } },
  { Meghalaya: { shortName: 'ML', xCoordinate: -7, yCoordinate: 5 } },
  { Mizoram: { shortName: 'MZ', xCoordinate: -7, yCoordinate: 5 } },
  { Nagaland: { shortName: 'NL', xCoordinate: -7, yCoordinate: 5 } },
  { Delhi: { shortName: 'DL', xCoordinate: -7, yCoordinate: 5 } },
  { Puducherry: { shortName: 'PY', xCoordinate: -7, yCoordinate: 5 } },
  { Punjab: { shortName: 'PB', xCoordinate: -7, yCoordinate: 5 } },
  { Sikkim: { shortName: 'SK', xCoordinate: -7, yCoordinate: 5 } },
  { Tripura: { shortName: 'TR', xCoordinate: -7, yCoordinate: 5 } },
  { WestBengal: { shortName: 'WB', xCoordinate: -7, yCoordinate: 5 } },
  { Odisha: { shortName: 'OR', xCoordinate: -7, yCoordinate: 5 } },
  { 'Jammu And Kashmir': { shortName: 'JK', xCoordinate: -7, yCoordinate: 5 } },
  { Ladakh: { shortName: 'LA', xCoordinate: -7, yCoordinate: 5 } },
];

const mapContentData = [
  {
    id: 11,
    text: 'Active',
    color: '#08ac5d',
  },
  {
    id: 13,
    text: 'Q4',
    color: '#094e8f',
  },

  {
    id: 14,
    text: 'FY 2025-’26',
    color: '#F178E9',
  },
];

const getInitialCoordinates = (state) => {
  const geo = geoData?.features?.find((g) => g?.properties?.st_nm?.toLowerCase() === state);
  const coordinates = geoCentroid(geo); // Get centroid of the state
  return coordinates ?? [0, 0];
};

const StateMap = ({ activestateByApi }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const activeState = searchParams.get('activeState');
  const [center, setCenter] = useState([]);

  const handleStateClick = (stateName, coordinates) => {
    setCenter(coordinates);
    navigate(`/our-network?activeState=${stateName?.toLowerCase()}`);
  };

  useEffect(() => {
    if (activeState) {
      const coOrds = getInitialCoordinates(activeState);
      setCenter(coOrds);
    }
  }, [activeState]);

  const stateColorCode = activestateByApi?.reduce((acc, stateObj) => {
    const stateColorObj = stateColorArray?.find(
      (state) => state?.stateName?.toLowerCase() === stateObj.stateName?.toLowerCase()
    );
    if (stateColorObj) {
      // Assign the appropriate color code based on the status
      if (stateObj.status === 'Active') {
        acc[stateColorObj.stateName] = stateColorObj.active;
      } else if (stateObj.status === 'FY') {
        acc[stateColorObj.stateName] = stateColorObj.FY;
      } else if (stateObj.status === 'Q4') {
        acc[stateColorObj.stateName] = stateColorObj.Q4;
      }
    }
    return acc;
  }, {});
  const stateActiveStatus = activestateByApi?.reduce((acc, stateObj) => {
    acc[stateObj.stateName] = stateObj?.isActive === true;
    return acc;
  }, {});

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true); // Mobile screen width (adjust as needed)
    } else {
      setIsMobile(false); // Desktop or larger screen width
    }
  };

  useEffect(() => {
    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize); // Add event listener on resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);
  return (
    <div className="custom-map-container-ui">
      <ComposableMap
        projection="geoMercator"
        projectionConfig={activeState ? { scale: 3000, center: center } : { scale: 1000, center: [80, 22] }}
      >
        <Geographies geography={geoData}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const stateName = geo.properties?.st_nm; // Adjust as per your GeoJSON
              const stateColor = stateColorCode?.[stateName] || '#e4e7e9'; // Default color
              const coordinates = geoCentroid(geo); // Get centroid of the state
              // Only render the clicked state or all states if no state is clicked
              if (activeState && activeState !== stateName?.toLowerCase()) {
                return null; // Skip rendering other states if one is selected
              }

              return (
                <React.Fragment key={geo.rsmKey}>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={stateColor}
                    onClick={() => {
                      if (stateActiveStatus?.[stateName.toLowerCase()]) handleStateClick(stateName, coordinates);
                    }}
                    className={`state-geography`}
                    stroke="#f6f7f7"
                  />
                  {/* Marker and Label */}
                  {coordinates && stateColorCode?.[stateName] && (
                    <Marker
                      coordinates={coordinates}
                      onClick={() => {
                        if (stateActiveStatus?.[stateName.toLowerCase()]) handleStateClick(stateName, coordinates);
                      }}
                    >
                      {stateActiveStatus?.[stateName.toLowerCase()] && isMobile && (
                        <circle
                          className="pulse-circle"
                          cx={stateName === 'Andhra Pradesh' ? -20 : -4} // Adjust the X position based on your logic
                          cy={stateName === 'Andhra Pradesh' ? 22 : 12}
                        />
                      )}
                      <text
                        style={{
                          fontSize: activeState ? '1rem' : '0.6rem',
                          fontWeight: activeState ? 700 : 'normal',
                          textTransform: activeState ? 'uppercase' : 'capitalize',
                        }}
                        // textAnchor="middle"
                        x={stateName === 'Andhra Pradesh' ? -20 : -10} // Adjust the X position based on your logic
                        y={stateName === 'Andhra Pradesh' ? 15 : 5}
                        className="map-state-text"
                      >
                        {/* {stateName} */}
                        {/* {stateName?.split(' ').map((word, index) => (
                          <tspan key={index} x="0" dy={index === 0 ? '0' : '1.2em'}>
                            {word}
                          </tspan>
                        ))} */}
                        {stateDetailsArray?.map((state) => state[stateName]?.shortName)}
                      </text>
                    </Marker>
                  )}
                </React.Fragment>
              );
            })
          }
        </Geographies>
      </ComposableMap>

      {!activeState && (
        <div className="floating-map-content">
          {mapContentData.map((d) => (
            <div className="floating-map-content-element">
              <span style={{ background: d.color }}></span> <span style={{ color: d.color }}>{d.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StateMap;
